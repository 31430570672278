import { defineStore } from "pinia";
import type {
  Coordinates,
  LocationOverlayProduct,
  SapStores,
} from "~/components/feature/SparLocation/SparLocationOverlay/SparLocationOverlay.types";
import {
  OutletAvailability,
  type EcomAvailabilitySchema,
  type OutletAvailabilitySchema,
  type OutletsAvailabilitySchema,
} from "~/utils/availability/integration/availability.types";
import { servicesEndpoints } from "~/utils/bff/endpoints.constants";
import { AvailabilityError } from "~/utils/error";
import { adjustOverlappingStores } from "~/utils/sapcc/stores";
import type { SapStore } from "~/utils/sapcc/stores/types";
import { getMiddlwareUrl } from "~/utils/ui";

export const useLocationStore = defineStore("location", () => {
  const stores = ref<SapStore[] | null>();
  const outletsList = ref<OutletsAvailabilitySchema>();
  const isAvailable = ref(false);
  const showMapOverlay = ref(false);
  const selectedProduct = ref<LocationOverlayProduct>();
  const ecomAvailability = ref<EcomAvailabilitySchema>();

  const { $t } = useNuxtApp();
  const { $availability } = useNuxtApp();

  const loadStores = async (
    coordinates: Coordinates,
    bounds: google.maps.LatLngBoundsLiteral | undefined,
    fields: string = "FULL",
  ) => {
    try {
      const uri = getMiddlwareUrl(useRuntimeConfig()) + servicesEndpoints.sapcc.stores;

      const { stores: apiStores } = await $fetch<SapStores>(uri, {
        method: "post",
        body: {
          baseSite: "national",
          fields,
          latitude: coordinates.lat,
          longitude: coordinates.lng,
          pageSize: 2500,
          ...bounds,
        },
      });

      // Add availability
      const newStores = apiStores?.map((store) => {
        // Check if the store was loaded before and use the cached data with (adjusted) coordinates
        const cachedStore = stores.value?.find((storeItem) => storeItem.storeId === store.storeId);

        const res = {
          ...(cachedStore || store),
          bestand: false,
        };

        if (outletsList.value === undefined) return res;

        const availabilityOutlet = outletsList.value[store.openingHours.code];
        return {
          ...res,
          bestand:
            availabilityOutlet !== undefined &&
            availabilityOutlet.availability === OutletAvailability.AVAILABLE,
        };
      });

      // Handle overlapping stores
      stores.value = adjustOverlappingStores(newStores);
    } catch (error) {
      Log.error(LogArea.sapcc, "error when requesting stores on sapcc");
    }
  };

  const loadOutletsAvailability = async (productId: string) => {
    try {
      const uri = getMiddlwareUrl(useRuntimeConfig()) + servicesEndpoints.availability.outlets;

      const res = await $fetch<OutletsAvailabilitySchema>(uri, {
        method: "post",
        body: {
          country: "at",
          productId,
        },
      });

      outletsList.value = res;
    } catch (error) {
      Log.error(LogArea.availability, "error when requesting availabilites");
    }
  };

  /**
   * Fetch availability for one product in one store
   * Uses $fetch instead of useFetch because the function is calles outside the setup function
   */
  const loadOutletAvailability = async (productId: string, storeId: string) => {
    const body = {
      country: "at",
      productId,
      storeId,
    };

    try {
      const uri = getMiddlwareUrl(useRuntimeConfig()) + servicesEndpoints.availability.outlet;

      return await $fetch<OutletAvailabilitySchema>(uri, {
        method: "post",
        body,
      });
    } catch (error) {
      Log.error(LogArea.availability, "error when requesting availability");
    }
  };

  /**
   * IMPORTANT - Please note, we do not add a key (useFetch unique key) here because we need a CSR reload on the PDP for this function.
   */
  const loadProductEcomAvailability = async (productId: string | string[]) => {
    try {
      const {
        data: { value: ecomAvailabilityData },
      } = await $availability.getEcomAvailability({
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: {
          country: "at",
          productId,
        },
      });

      ecomAvailability.value = ecomAvailabilityData as EcomAvailabilitySchema;
    } catch (error) {
      throw new AvailabilityError($t("availability.error.ecom_availability_error"));
    }
  };

  return {
    loadProductEcomAvailability,
    isAvailable,
    ecomAvailability,
    loadOutletsAvailability,
    loadOutletAvailability,
    loadStores,
    outletsList,
    selectedProduct,
    showMapOverlay,
    stores,
  };
});
